import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { IntlProvider, addLocaleData } from "react-intl"
// Locale data
import enData from "react-intl/locale-data/en"
import ptData from "react-intl/locale-data/pt"
import esData from "react-intl/locale-data/es"
import frData from "react-intl/locale-data/fr"
import idData from "react-intl/locale-data/id"

// Messages
import en from "../../i18n/en.json"
import pt from "../../i18n/pt.json"
import fr from "../../i18n/fr.json"
import es from "../../i18n/es.json"
import id from "../../i18n/id.json"

// Style
import "../../styles/global.css"
// Components
import Header from "../Header"
import Footer from "../Footer"

const messages = { en, pt, fr, es, id }

addLocaleData([...enData, ...ptData, ...esData, ...frData, ...idData])

const Layout = ({ locale, children, location }) => (
  <IntlProvider locale={locale} messages={messages[locale]}>
    <Fragment>
      <div className="container">
        <Header location={location} />

        <div className="container-inner">{children}</div>
      </div>
      <Footer />
    </Fragment>
  </IntlProvider>
)

Layout.propTypes = {
  locale: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
}

export default Layout
