import React, { useState } from "react"
import { Link } from "gatsby"
import { injectIntl, intlShape, FormattedMessage } from "react-intl"
import classnames from "classnames"

import locales from "../../constants/locales"
import { START_FREE_WEEK_LINK } from "../../constants/links"

import PremiumLogo from "../../images/premium-logo-orange.svg"

const Header = ({ intl: { locale }, location }) => {
  const [navOpen, setNavOpen] = useState(false)
  const pathName = location.pathname
  const pathIsFaqs = pathName.includes("faqs")
  const pathIsAccess = pathName.includes("access")
  const pathIsHome = !pathIsFaqs && !pathIsAccess

  return (
    <div className="nav-container">
      <div className="nav-outer-wrapper">
        <div className="nav-wrapper-inner">
          <Link
            className="logo-link"
            to={locale === "en" ? "/" : `/${locale}/`}
          >
            <img
              className="premium-logo"
              src={PremiumLogo}
              alt="Wattpad Premium logo"
            />
          </Link>
        </div>
        <div className="nav-wrapper-inner">
          <button
            className="hamburger-icon hide-desktop"
            onClick={() => setNavOpen(!navOpen)}
          >
            <svg
              width="18"
              height="12"
              viewBox="0 0 18 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 2V0H18V2H0ZM0 7H18V5H0V7ZM0 12H18V10H0V12Z"
                fill="#222222"
              />
            </svg>
          </button>

          <div
            className={classnames("topnav", { responsive: navOpen })}
            id="main-nav"
          >
            <Link
              to={locale === "en" ? "/" : `/${locale}/`}
              className={classnames("black-txt", "page-link", {
                "page-active": pathIsHome,
              })}
            >
              <div className="border-container">
                <FormattedMessage id="navHome" />
              </div>
            </Link>
            <Link
              to={
                locale === "en"
                  ? "/access-premium/"
                  : `/${locale}/access-premium/`
              }
              className={classnames("black-txt", "page-link", {
                "page-active": pathIsAccess,
              })}
            >
              <div className="border-container">
                <FormattedMessage id="navHowTo" />
              </div>
            </Link>
            <Link
              to={locale === "en" ? "/faqs/" : `/${locale}/faqs/`}
              className={classnames("black-txt", "page-link", {
                "page-active": pathIsFaqs,
              })}
            >
              <div className="border-container">
                <FormattedMessage id="navFaqs" />
              </div>
            </Link>
            <div className="dropdown">
              <div className="border-container">
                <button className="dropbtn black-txt">
                  {locales[locale].locale}
                  <i className="fa fa-caret-down" />
                </button>
              </div>
              <div className="dropdown-content">
                {Object.keys(locales).map(key => {
                  let toPath = "/"
                  if (!locales[key].default) {
                    toPath += `${locales[key].path}/`
                  }

                  if (pathIsFaqs) {
                    toPath += "faqs/"
                  } else if (pathIsAccess) {
                    toPath += "access-premium/"
                  }
                  return (
                    <Link key={key} to={toPath} className="black-txt">
                      {locales[key].locale}
                    </Link>
                  )
                })}
              </div>
            </div>
            <div className="start-free-trial-wrapper page-link">
              <div className="border-container">
                <a
                  href={START_FREE_WEEK_LINK}
                  className="btn-primary start-free-trial"
                >
                  <FormattedMessage id="startFreeWeekShort" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Header.propTypes = { intl: intlShape.isRequired }

export default injectIntl(Header)
