import React from "react"
import { injectIntl, intlShape, FormattedMessage } from "react-intl"
import WattyBottomLogo from "../../images/wp-logo-bottom.png"

const Footer = ({ intl: { locale } }) => (
  <div className="footer-wrapper">
    <div className="inner-content">
      <div className="row pad-lr">
        <div id="wp-logo-bottom">
          <img src={WattyBottomLogo} alt="Logo" />
        </div>
      </div>
      <div className="row pad-lr">
        <div className="flex" id="bottom-links-left">
          <p className="white-font regular-txt pad-xstxsb uppercase">
            <FormattedMessage id="companyNav" />
          </p>
          <div className="bottom-nav">
            <ul>
              <li className="white-font pad-xstxsb">
                <a href="https://company.wattpad.com/">
                  <FormattedMessage id="aboutNav" />
                </a>
              </li>
              <li className="white-font pad-xstxsb">
                <a href="https://support.wattpad.com/hc/en-us/articles/200774224-Privacy-Policy">
                  <FormattedMessage id="privacyNav" />
                </a>
              </li>
              <li className="white-font pad-xstxsb">
                <a href="https://support.wattpad.com/hc/en-us/articles/200774344-Terms-of-Service">
                  <FormattedMessage id="termsNav" />{" "}
                </a>
              </li>
            </ul>
            <ul>
              <li className="white-font pad-xstxsb">
                <a href="https://company.wattpad.com/press/">
                  <FormattedMessage id="pressNav" />
                </a>
              </li>
              <li className="white-font pad-xstxsb">
                <a href="https://support.wattpad.com/hc/en-us/requests/new">
                  <FormattedMessage id="contactNav" />
                </a>
              </li>
              <li className="white-font pad-xstxsb">
                <a href="https://company.wattpad.com/blog">
                  <FormattedMessage id="studiosNav" />
                </a>
              </li>
            </ul>
            <ul>
              <li className="white-font pad-xstxsb">
                <a href="https://company.wattpad.com/work-at-wattpad/">
                  <FormattedMessage id="jobsNav" />
                </a>
              </li>
              <li className="white-font pad-xstxsb">
                <a href="https://support.wattpad.com/hc/en-us/articles/200774334">
                  <FormattedMessage id="copyrightNav" />
                </a>
              </li>
              <li className="white-font pad-xstxsb">
                <a href="http://business.wattpad.com/brand-partnerships/">
                  <FormattedMessage id="brandNav" />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="flex" id="bottom-links-middle">
          <p className="white-font regular-txt pad-xstxsb uppercase">
            <FormattedMessage id="communityNav" />
          </p>
          <div className="bottom-nav">
            <ul>
              <li className="white-font pad-xstxsb">
                <a href="https://www.wattpad.com/user/wattpad">Wattpad</a>
              </li>
              <li className="white-font pad-xstxsb">
                <a href="https://www.youtube.com/user/wattpad">YouTube</a>
              </li>
              <li className="white-font pad-xstxsb">
                <a href="https://www.instagram.com/wattpad/">Instagram</a>
              </li>
              <li className="white-font pad-xstxsb">
                <a href="https://www.facebook.com/wattpad/">Facebook</a>
              </li>
              <li className="white-font pad-xstxsb">
                <a href="https://twitter.com/wattpad/">Twitter</a>
              </li>
              <li className="white-font pad-xstxsb">
                <a href="https://www.tiktok.com/@wattpad">TikTok</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
)

Footer.propTypes = { intl: intlShape.isRequired }

export default injectIntl(Footer)
