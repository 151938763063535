module.exports = {
    en: {
      path: 'en',
      locale: 'English',
      default: true
    },
    fr: {
      path: 'fr',
      locale: 'Français'
    },
    es: {
      path: 'es',
      locale: 'Español'
    },
    pt: {
      path: 'pt',
      locale: 'Português'
    },
    id: {
      path: 'id',
      locale: 'Bahasa Indonesia'
    }

  }